'use client'

import { ReactComponent as ArrowLeftIcon } from '@brand/icons/back.svg'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { ReactComponent as SearchIcon } from '@brand/icons/search.svg'
import { ReactComponent as HouseIcon } from '@brand/icons/house.svg'
import { ReactComponent as ApartmentIcon } from '@brand/icons/apartment.svg'
import { ReactComponent as LocationIcon } from '@brand/icons/location.svg'
import { ReactComponent as BookmarkIcon } from '@brand/icons/bookmark.svg'
import { scrollToElement } from '@rentpath/web-utils'
import { usePathname } from 'next/navigation'
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import { useCombobox } from 'downshift'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import Cookies from 'js-cookie'
import {
  memo,
  startTransition,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
  type MouseEvent,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEventHandler,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Highlighter } from '../../../components/highlighter/highlighter'
import { Truncate } from '../../../components/truncate/truncate'
import { emailCaptureViewCountAtom } from '../../email-capture/email-capture.store'
import { SEARCH_HISTORY_COOKIE } from '../hooks/search-history.const'
import { useRouteParams } from '../route-params.context'
import { useHandleClickOutside, useHasMounted } from '@rentpath/react-hooks'
import styles from './search-combobox.module.css'
import { searchComboboxToScrollyOffset } from '@brand/slots/home/home-page-hero/search-combobox-to-scroll.const'
import { isNewSearchAtom, searchComboboxOpened } from './search-combobox.store'
import { Spinner } from '../../../components/spinner/spinner'
import { createLogger } from '../../logger/logger'
import { userSavedSearches } from '../../user/user.store'
import { yieldOrContinue } from 'main-thread-scheduling'
import { useRequestData } from '../../request-data/pages-router/use-request-data'
import { PropertyType } from '../../../__generated__/api-types'
import { propertyTypeMap } from '@brand/search/search-results'
import type {
  SearchComboBoxItem,
  SearchComboBoxProps,
  SearchComboboxResults,
} from './search-combobox.types'
import {
  RESULTS_DEFAULT,
  getIsPropertyPath,
  fetchAndTransformSearchComboBoxResults,
} from './search-combobox.utils'

const CurrentLocationButton = dynamic(() =>
  import('./current-location-button/current-location-button').then(
    (mod) => mod.CurrentLocationButton
  )
)

const logger = createLogger('search-combobox')

export const SearchComboBox = memo(SearchComboBoxBase)

export function SearchComboBoxBase(props: SearchComboBoxProps) {
  const {
    onSelect,
    appBannerDismissedCookie,
    isModal,
    scrollToTopOnFocus,
    autoFetch,
    formRef,
    initialValue,
    onSuccess,
    recentSearch,
    propertyTypes,
  } = props
  const defaultQueryValue = recentSearch || initialValue || ''
  const searchParams = useRouteParams()
  const { ipLocation } = useRequestData()
  const hasBbox = Boolean(searchParams.bbox)
  const isExactLocationSearch = Boolean(searchParams.userLatLng)
  const [isModalOpen, setIsModalOpen] = useAtom(searchComboboxOpened)
  const [showResults, setShowResults] = useState(false)
  const [suppressDropdown, setSuppressDropdown] = useState(false)
  const [noResultMessage, setNoResultMessage] = useState(false)
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [results, setResults] = useState<SearchComboboxResults>(RESULTS_DEFAULT)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [query, setQuery] = useState(() =>
    isExactLocationSearch
      ? 'Current Location'
      : hasBbox
      ? 'Map Location'
      : defaultQueryValue
  )
  const hasResults = results.places.length > 0 || results.properties.length > 0
  const inputRef = useRef<HTMLInputElement | null>(null)
  const queryHolderRef = useRef('')
  const selectionHolderRef = useRef<SearchComboBoxItem | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState(false)
  const setEmailCaptureViewCount = useSetAtom(emailCaptureViewCountAtom)
  const setIsNewSearchValue = useSetAtom(isNewSearchAtom)
  const searchComboboxRef = useRef<HTMLDivElement>(null)
  const SearchBoxIcon = props.showSearchIcon ? SearchIcon : CloseIcon
  const pathName = usePathname()
  const isHydrated = useHasMounted()

  const placesAndProperties = useMemo(
    function initializePlacesAndProperties() {
      return results.places.concat(results.properties)
    },
    [results.places, results.properties]
  )

  const allSavedSearches = useAtomValue(userSavedSearches)

  const savedSearches = useMemo(
    function initializeSavedSearches() {
      return (allSavedSearches ?? []).reduce<
        { value: string; label: string }[]
      >((acc, savedSearch) => {
        if (
          acc.length < 3 &&
          savedSearch.name.toLowerCase().startsWith(query.toLowerCase())
        ) {
          acc.push({
            value: savedSearch.searchUrl,
            label: savedSearch.name,
          })
        }
        return acc
      }, [])
    },
    [allSavedSearches, query]
  )

  /**
   * Even though we are iterating over each of these parts of the combined array below,
   * we still need to provide all items for downshift to keep track of the selectedItem.
   * We iterate over individual parts below to simplify the logic for highlighting and selecting
   * active items in the dropdow while allowing for section headings.
   */
  const allItems = useMemo(
    () => savedSearches.concat(placesAndProperties),
    [savedSearches, placesAndProperties]
  )

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox<SearchComboBoxItem>({
    defaultHighlightedIndex: 0,
    inputValue:
      isExactLocationSearch && !isFocus
        ? 'Current Location'
        : hasBbox && !isFocus
        ? 'Map Location'
        : query,
    onInputValueChange(changes) {
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        handleChange(changes.inputValue)
      }

      if (changes.type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
        setShowResults(false)
        onBlur()
      }
    },
    onSelectedItemChange: (changes) => {
      if (changes.type !== useCombobox.stateChangeTypes.InputKeyDownEnter) {
        return
      }

      // this prop should always take precendence over form submission
      if (props.callHandleSubmitOnEnter) {
        void handleSubmitButton()
        return
      }

      if (formRef?.current) {
        setShowResults(false)
        setQuery(changes.inputValue ?? '')
        setCurrentSearchTerm('')
        // If the instance is contained in a form, treat
        // it like a normal input when a user presses "Enter".
        formRef?.current.submit()
        return
      }

      void handleSelection(changes.selectedItem || allItems[0])
    },
    items: allItems,
    itemToString(item) {
      return item ? item.label : ''
    },
    onStateChange: (changes) => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange:
          handleInput(changes.inputValue || '')
          break

        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            void handleSelection(changes.selectedItem)
          }
          break
        case useCombobox.stateChangeTypes.InputBlur:
          handleBlur()
          break
        default:
          break
      }
    },
  })

  useHandleClickOutside(
    containerRef,
    () => setShowResults(false),
    'click',
    showResults
  )

  const fetchResults = useCallback(
    (value: string) =>
      fetchAndTransformSearchComboBoxResults({
        query: value,
        refinements: searchParams.refinements,
        propertyTypes: propertyTypes || searchParams.propertyTypes,
        singlePropertyTypeFilterParam: searchParams['property-type'],
        moveInDate: searchParams['move_in_date'],
        pathName,
      }).tapError((err) => {
        setResults(RESULTS_DEFAULT)
        setShowResults(false)
        setLoading(false)
        logger.error(err)
      }),
    [searchParams, pathName, propertyTypes]
  )

  const handleChangeDebounced = useDebouncedCallback((value: string) => {
    if (!isFocus) {
      setQuery('')
      return
    }

    if (value.length >= 3) {
      setLoading(true)
      setNoResultMessage(false)
    } else {
      setLoading(false)
      setShowResults(false)
      setNoResultMessage(false)
      return
    }

    startTransition(() => {
      void fetchResults(value)
        .tapOk((res) => {
          setLoading(false)
          setError(null)
          if (res.places.length > 0 || res.properties.length > 0) {
            setResults(res)
            setNoResultMessage(false)
            setShowResults(true)
          } else {
            setResults(RESULTS_DEFAULT)
            setShowResults(false)
            setNoResultMessage(true)
          }
        })
        .tapError((err) => {
          setError(err)
        })
    })
  }, 200)

  const handleChange = useCallback(
    (value: string) => {
      setQuery(value)
      handleChangeDebounced(value)
      setIsFocus(true)
      setError(null)

      if (value === '' && hasResults) {
        if (!isModal) {
          setResults(RESULTS_DEFAULT)
          setShowResults(false)
        }
      }
    },
    [handleChangeDebounced, hasResults, isModal]
  )

  const handleInput = useCallback((value: string) => {
    setCurrentSearchTerm(value)
  }, [])

  const onBlur = useCallback(() => {
    setIsFocus(false)
    setError(null)
    setNoResultMessage(false)
    if (!currentSearchTerm) {
      if (queryHolderRef.current) {
        setQuery(queryHolderRef.current)
        queryHolderRef.current = ''
      }

      if (selectionHolderRef.current) {
        selectionHolderRef.current = null
      }

      if (!queryHolderRef.current && !selectionHolderRef.current) {
        setQuery(defaultQueryValue)
      }
    }
  }, [currentSearchTerm, defaultQueryValue])

  const handleBlur = useCallback(() => {
    if (
      !searchComboboxRef.current?.contains(document.activeElement) &&
      !isModalOpen
    ) {
      onBlur()
    }
  }, [isModalOpen, onBlur])

  const handleFocus = useCallback(
    async function handleFocus(e: FocusEvent<HTMLInputElement>) {
      if (e.target !== inputRef.current) {
        return
      }

      await yieldOrContinue('interactive')
      if (isModal) {
        setIsModalOpen(true)
      }

      setIsFocus(true)
      selectionHolderRef.current = selectedItem
      setIsNewSearchValue(false)
      queryHolderRef.current = query
      if (!currentSearchTerm) {
        setQuery('')
      }

      if (hasResults) {
        setShowResults(true)
      }

      if (scrollToTopOnFocus) {
        setTimeout(() => {
          void scrollToElement(
            containerRef.current,
            appBannerDismissedCookie ? -10 : searchComboboxToScrollyOffset
          )
        }, 100)
      }
    },
    [
      currentSearchTerm,
      hasResults,
      appBannerDismissedCookie,
      isModal,
      scrollToTopOnFocus,
      query,
      selectedItem,
      setIsModalOpen,
      setIsNewSearchValue,
    ]
  )

  const handleSelection = useCallback(
    async (item: SearchComboBoxItem, suppressTagging = false) => {
      setIsNewSearchValue(true)
      setQuery(item.label)

      const isPropertyPath = getIsPropertyPath(item)

      setIsFocus(false)
      const existingSearchHistory = Cookies.get(SEARCH_HISTORY_COOKIE)
      const searchHistory = existingSearchHistory
        ? JSON.parse(existingSearchHistory)?.[0]
        : {}
      if (isPropertyPath) {
        const searchHistoryEntry = [
          {
            ...searchHistory,
            propertyName: item.label,
          },
        ]
        await yieldOrContinue('idle')
        Cookies.set(SEARCH_HISTORY_COOKIE, JSON.stringify(searchHistoryEntry))
      } else if (searchHistory.locationSlug) {
        await yieldOrContinue('idle')
        setEmailCaptureViewCount((prev) => prev + 1)
      }

      queryHolderRef.current = ''
      selectionHolderRef.current = null
      setResults(RESULTS_DEFAULT)
      setIsModalOpen(false)
      onSelect?.(item, isPropertyPath)
      setShowResults(false)
      setCurrentSearchTerm('')
      // this is a work around to blur the input field after selection
      // not sure if they are going to fix it, but for not doing the
      // following is needed to blur our input on list item click
      requestAnimationFrame(() => setTimeout(() => inputRef.current?.blur(), 0))

      if (!suppressTagging) {
        await yieldOrContinue('idle')
        window.eventTracker?.track('click', {
          section: 'search_input',
          item: item.label,
        })
      }
    },
    [onSelect, setEmailCaptureViewCount, setIsModalOpen, setIsNewSearchValue]
  )

  const handleSubmitButton = useCallback(async () => {
    // highlighted index can be -1 if the user has not interacted with the dropdown therefore we need to default to 0 if no selection
    const safeHighlightedIndex = highlightedIndex !== -1 ? highlightedIndex : 0

    // only try to use the activeDropdown label if places/properties have loaded
    const activePlacesAndPropertiesDropdownLabel =
      placesAndProperties.length > 0 &&
      // subtract saved searches, which appear above place results, from the index
      placesAndProperties[safeHighlightedIndex - savedSearches.length].label

    const searchTerm =
      activePlacesAndPropertiesDropdownLabel ||
      currentSearchTerm ||
      recentSearch

    if (safeHighlightedIndex < savedSearches.length && !searchTerm) {
      // If a saved search is highlighted - don't need to fetch results based on the search term
      const result = savedSearches[safeHighlightedIndex]
      onSuccess?.({
        res: { savedSearches },
        setSuppressDropdown,
      })
      startTransition(() => {
        void handleSelection(result, true)
      })

      await yieldOrContinue('idle')
      window.eventTracker?.track('click', {
        section: 'search_input',
        item: 'search_button',
        customSearchInput: result.label, // maps to cd56
      })
    } else if (searchTerm) {
      fetchAndTransformSearchComboBoxResults({
        query: searchTerm,
        refinements: searchParams.refinements,
        propertyTypes: propertyTypes || searchParams.propertyTypes,
        singlePropertyTypeFilterParam: searchParams['property-type'],
        moveInDate: searchParams['move_in_date'],
        pathName,
      })
        .mapOk(async (res) => {
          const firstResult = [...res.places, ...res.properties][0]
          onSuccess?.({ res, setSuppressDropdown })
          startTransition(() => {
            void handleSelection(firstResult, true)
          })

          await yieldOrContinue('idle')
          window.eventTracker?.track('click', {
            section: 'search_input',
            item: 'search_button',
            customSearchInput: firstResult.label, // maps to cd56
          })
        })
        .mapError((err) => {
          setResults(RESULTS_DEFAULT)
          setError(err)
          setShowResults(false)
        })
      setCurrentSearchTerm('')
    }
  }, [
    propertyTypes,
    savedSearches,
    currentSearchTerm,
    recentSearch,
    onSuccess,
    handleSelection,
    searchParams,
    highlightedIndex,
    placesAndProperties,
    pathName,
  ])

  const handleClearResults = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (isModal) {
        setIsModalOpen(true)
        inputRef.current?.focus()
      } else {
        onBlur()
        e.currentTarget.blur()
        setResults(RESULTS_DEFAULT)
      }

      inputRef.current?.focus()
      queryHolderRef.current = query
      setQuery('')
      setShowResults(false)
      setCurrentSearchTerm('')
    },
    [isModal, query, setIsModalOpen, onBlur]
  )

  const handleModalClose: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      setIsModalOpen(false)
      setShowResults(false)
      onBlur()
    },
    [onBlur, setIsModalOpen]
  )

  useEffect(() => {
    if (searchParams.location) {
      setQuery(defaultQueryValue)
    }
  }, [defaultQueryValue, searchParams])

  useEffect(() => {
    if (autoFetch && initialValue) {
      startTransition(() => {
        fetchResults(initialValue).tapOk((res) => {
          if (res.places.length > 0 || res.properties.length > 0) {
            setResults(res)
          }
        })
      })
    }
  }, [fetchResults, autoFetch, initialValue])

  useEffect(() => {
    if (isModalOpen && results && isModal) {
      setShowResults(true)
    }
  }, [results, isModal, isModalOpen, showResults])

  const [
    shouldShowModalSearchLoadingIndicator,
    setShouldShowModalSearchLoadingIndicator,
  ] = useState(false)
  useEffect(() => {
    const speed = window.navigator?.connection?.effectiveType
    const timeout = speed && speed === '4g' ? 199 : 0

    // avoid flashing on fast connections
    const timer = setTimeout(() => {
      if (isModal) {
        setShouldShowModalSearchLoadingIndicator(loading)
      }
    }, timeout)
    return () => clearTimeout(timer)
  }, [loading, isModal])

  const isMenuVisible =
    (showResults ||
      // saved searches should appear on focus even if nothing is typed
      (savedSearches.length > 0 && isFocus) ||
      error !== null ||
      noResultMessage) &&
    !suppressDropdown

  return (
    <div
      className={clsx(
        // The idea here is that if it's not a modal,
        // we use `display:contents` to just pass through,
        // as if this element didn't exist. Otherwise, this
        // element is used as the container for the fixed
        // positioned fullscreen container.
        props.isModal ? styles.isModal : styles.isNotModal,
        isModalOpen && styles.isModalOpen
      )}
    >
      <div
        className={clsx(
          'searchCombobox',
          styles.root,
          !isHydrated && styles.disabled,
          props.className,
          isFocus && props.searchBoxFocusedClassName
        )}
        data-tag_section="search_input"
        data-tid="autocomplete-search-bar"
        id={props.id}
        ref={containerRef}
      >
        <label className="sr-only" {...getLabelProps()}>
          Search for homes by location
        </label>

        <div
          className={clsx(
            styles.searchContainer,
            props.searchInputContainerClassName,
            isMenuVisible && styles.searchContainerVisible
          )}
          ref={searchComboboxRef}
        >
          <div className={clsx(styles.inputWrap)}>
            {props.isModal && isModalOpen && (
              <button
                aria-label="Cancel Search"
                className={styles.backButton}
                onClick={handleModalClose}
                type="button"
              >
                <ArrowLeftIcon />
              </button>
            )}
            {formRef?.current ? (
              <input
                type="hidden"
                name="query"
                value={selectedItem?.label || ''}
              />
            ) : null}
            <input
              className={clsx(styles.input, props.searchInputClassName)}
              type="search"
              data-tid="search-input-field"
              placeholder={
                props.noPlaceholder
                  ? ''
                  : props.placeholder ?? 'City, Neighborhood, ZIP'
              }
              disabled={!isHydrated}
              aria-disabled={!isHydrated}
              {...getInputProps({
                // onStateChange does not have stateType equivalents for focus and tab pagination
                // so we need to handle this at the input level
                onFocus: handleFocus,
                onKeyDownCapture: (e: KeyboardEvent<HTMLInputElement>) => {
                  // disable default behavior where headless
                  // auto-selects the first item on blur.
                  if (e.key === 'Tab') {
                    setShowResults(false)
                    onBlur()
                  }
                },
                ref: inputRef,
              })}
            />

            {props.showSearchIcon && (
              <span className={styles.searchIcon}>
                <SearchBoxIcon aria-hidden="true" />
              </span>
            )}

            {props.showClearSearchButton && (
              <button
                aria-label="Clear Search Result"
                onClick={handleClearResults}
                type="button"
                disabled={!isHydrated}
                aria-disabled={!isHydrated}
                className={clsx(
                  styles.clearSearchTextButton,
                  props.clearSearchTextButtonClassName
                )}
              >
                <SearchBoxIcon aria-hidden="true" />
              </button>
            )}

            {!props.hideSearchButton && (
              <button
                type="button"
                onClick={handleSubmitButton}
                className={clsx(
                  styles.searchButton,
                  props.searchButtonClassName
                )}
                data-tid="search-button"
                data-tag_action="ignore"
                disabled={!isHydrated}
                aria-disabled={!isHydrated}
                aria-label={
                  typeof props.searchButtonNode !== 'string'
                    ? 'Submit Search'
                    : ''
                }
              >
                {isModalOpen ? props.searchButtonNode : 'Search'}
              </button>
            )}

            {loading && (
              <div
                aria-hidden
                className={clsx(
                  styles.loadingIndicator,
                  props.loadingIndicatorClassName
                )}
              />
            )}
          </div>

          <ul
            className={clsx(
              styles.listWrapper,
              props.resultsWrapperClassName,
              isMenuVisible && styles.listWrapperVisible
            )}
            data-tag_action="ignore"
            {...getMenuProps()}
          >
            {isMenuVisible && (
              <>
                {error || noResultMessage ? (
                  <li>
                    {error ? 'Sorry, there was an issue.' : 'No Results Found'}
                  </li>
                ) : (
                  <>
                    {shouldShowModalSearchLoadingIndicator ? (
                      <li className={styles.modalSearchLoadingIndicator}>
                        <Spinner />
                      </li>
                    ) : (
                      (savedSearches.length > 0 ||
                        results.places.length > 0 ||
                        results.properties.length > 0) && (
                        <>
                          {ipLocation?.city &&
                            ipLocation?.state &&
                            props.isModal && (
                              <li
                                className={clsx(
                                  styles.listItem,
                                  styles.currentLocationListItem
                                )}
                              >
                                <CurrentLocationButton
                                  searchParams={searchParams}
                                  ipLocation={ipLocation}
                                />
                              </li>
                            )}
                          {savedSearches.length > 0 && (
                            <>
                              <li
                                className={clsx(
                                  styles.listHeader,
                                  props.searchResultsHeaderClassName
                                )}
                              >
                                Saved Searches
                              </li>
                              {savedSearches.map((result, index) => (
                                <li
                                  className={styles.listItemWrap}
                                  key={result.value + index}
                                  {...getItemProps({
                                    item: result,
                                    index,
                                  })}
                                >
                                  <div
                                    className={clsx(
                                      styles.listItem,
                                      (selectedItem?.value === result.value ||
                                        highlightedIndex === index) &&
                                        styles.listItemHighlighted,
                                      props.listItemClassName
                                    )}
                                  >
                                    <BookmarkIcon className={styles.icon} />
                                    <Truncate as="span">
                                      <Highlighter
                                        className={styles.listItemTextHighlight}
                                        ignoreCase
                                        pattern={query}
                                      >
                                        {result.label}
                                      </Highlighter>
                                    </Truncate>
                                  </div>
                                </li>
                              ))}
                            </>
                          )}
                          {results.places.length > 0 && (
                            <>
                              <li
                                className={clsx(
                                  styles.listHeader,
                                  props.searchResultsHeaderClassName
                                )}
                              >
                                Places
                              </li>
                              {results.places.map((result, index) => {
                                const currentIdx = savedSearches.length + index
                                return (
                                  <li
                                    className={styles.listItemWrap}
                                    data-tag_action="ignore"
                                    {...getItemProps({
                                      item: result,
                                      index: currentIdx,
                                    })}
                                    key={result.value + currentIdx}
                                  >
                                    <div
                                      className={clsx(
                                        styles.listItem,
                                        (selectedItem?.value === result.value ||
                                          highlightedIndex === currentIdx) &&
                                          styles.listItemHighlighted,
                                        props.listItemClassName
                                      )}
                                    >
                                      <LocationIcon className={styles.icon} />

                                      <Truncate as="span">
                                        <Highlighter
                                          className={
                                            styles.listItemTextHighlight
                                          }
                                          ignoreCase
                                          pattern={query}
                                        >
                                          {result.label}
                                        </Highlighter>
                                      </Truncate>
                                    </div>
                                  </li>
                                )
                              })}
                            </>
                          )}

                          {results.properties.length > 0 && (
                            <>
                              <li
                                className={clsx(
                                  styles.listHeader,
                                  props.searchResultsHeaderClassName
                                )}
                              >
                                Properties
                              </li>

                              {results.properties.map((result, index) => {
                                const { city, stateAbbr } =
                                  result.location ?? {}
                                const hideName = Boolean(
                                  city &&
                                    stateAbbr &&
                                    result.name &&
                                    result.address
                                      ?.toLowerCase()
                                      .startsWith(
                                        `${result.name?.toLowerCase()},`
                                      )
                                )
                                const isApartmentType =
                                  result.propertyType ===
                                  PropertyType.Apartments
                                const currentIdx =
                                  savedSearches.length +
                                  results.places.length +
                                  index

                                return (
                                  result.address &&
                                  result.propertyType &&
                                  result.name && (
                                    <li
                                      className={styles.listItemWrap}
                                      key={result.value + currentIdx}
                                      {...getItemProps({
                                        item: result,
                                        index: currentIdx,
                                      })}
                                    >
                                      <div
                                        className={clsx(
                                          styles.propertiesListItem,
                                          (selectedItem?.value ===
                                            result.value ||
                                            highlightedIndex === currentIdx) &&
                                            styles.listItemHighlighted,
                                          props.listItemClassName
                                        )}
                                      >
                                        {isApartmentType ? (
                                          <ApartmentIcon
                                            className={styles.icon}
                                          />
                                        ) : (
                                          <HouseIcon className={styles.icon} />
                                        )}
                                        <Truncate
                                          as="div"
                                          className={
                                            styles.propertyListItemInfo
                                          }
                                        >
                                          {!hideName && (
                                            <Truncate
                                              as="span"
                                              className={styles.fullWidth}
                                            >
                                              <Highlighter
                                                className={
                                                  styles.listItemTextHighlight
                                                }
                                                ignoreCase
                                                pattern={query}
                                              >
                                                {result.name}
                                              </Highlighter>
                                            </Truncate>
                                          )}
                                          <Truncate
                                            as="span"
                                            className={styles.fullWidth}
                                          >
                                            <Highlighter
                                              className={
                                                styles.listItemTextHighlight
                                              }
                                              ignoreCase
                                              pattern={query}
                                            >
                                              {result.address}
                                            </Highlighter>
                                          </Truncate>
                                          <span
                                            className={
                                              styles.propertiesListItemType
                                            }
                                          >
                                            {
                                              propertyTypeMap[
                                                result.propertyType
                                              ]
                                            }
                                          </span>
                                        </Truncate>
                                      </div>
                                    </li>
                                  )
                                )
                              })}
                            </>
                          )}
                        </>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
