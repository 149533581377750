import clsx from 'clsx'
import type { SpinnerProps } from '../../components/spinner/spinner'
import { Spinner } from '../../components/spinner/spinner'
import styles from './dialog-body-loading-indicator.module.css'

interface DialogBodyLoadingIndicatorProps {
  className?: string
  variant?: SpinnerProps['variant']
}

export function DialogBodyLoadingIndicator(
  props: DialogBodyLoadingIndicatorProps
) {
  return (
    <div className={clsx(props.className, styles.spinner)}>
      <Spinner variant={props.variant} />
    </div>
  )
}
