'use client'

import styles from './tour-wizard-modal.module.css'
import { DialogModal } from '../dialog-modal/dialog-modal'
import { DialogBodyLoadingIndicator } from '../dialog-modal/dialog-body-loading-indicator'
import {
  type ComponentProps,
  startTransition,
  useCallback,
  useRef,
  lazy,
  Suspense,
} from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'
import {
  resetTourReuqestErrorAtom,
  resetCurrentStepAtom,
} from './tour-wizard.store'
import { useAtom, useSetAtom } from 'jotai'
import { tourWizardModalStateAtom } from '../cta-modals/cta-modals.atom'

export type TourWizardListing = ComponentProps<
  typeof TourWizardModalBody
>['listing']

type TourWizardModalProps = {
  'data-tid'?: string
  id?: string
}

export const TOUR_WIZARD_MODAL_ID = 'tour-wizard-modal'

const TourWizardModalBody = lazy(() => import('./tour-wizard-modal-body'))

export function TourWizardModal(props: TourWizardModalProps) {
  // props.listing can be null to indicate the modal is closed. We render a
  // Modal anyway so that CSS transitions will continue to work.
  const [
    { listing, currentRefinementSlugs, locationRelativeToSearchedCity },
    setTourWizardModalAtom,
  ] = useAtom(tourWizardModalStateAtom)
  const tourWizardModalRef = useRef<HTMLDialogElement>(null)
  const resetCurrentStep = useSetAtom(resetCurrentStepAtom)
  const resetTourRequestError = useSetAtom(resetTourReuqestErrorAtom)

  const onClose = useCallback(
    async function onClose() {
      await yieldOrContinue('smooth')
      tourWizardModalRef?.current?.close()
      await yieldOrContinue('smooth')
      setTourWizardModalAtom({ listing: null })
    },
    [setTourWizardModalAtom]
  )

  const onAfterClose = useCallback(
    function onAfterClose() {
      startTransition(() => {
        resetCurrentStep()
        resetTourRequestError()
        setTourWizardModalAtom({ listing: null })
      })
    },
    [setTourWizardModalAtom, resetCurrentStep, resetTourRequestError]
  )

  return (
    <DialogModal
      className={styles.tourWizardModal}
      id={TOUR_WIZARD_MODAL_ID}
      data-tid={props['data-tid'] || 'tour-modal'}
      ref={tourWizardModalRef}
      onAfterClose={onAfterClose}
    >
      {listing ? (
        <Suspense fallback={<DialogBodyLoadingIndicator />}>
          <TourWizardModalBody
            listing={listing}
            currentRefinementSlugs={currentRefinementSlugs}
            onClose={onClose}
            locationRelativeToSearchedCity={locationRelativeToSearchedCity}
          />
        </Suspense>
      ) : null}
    </DialogModal>
  )
}
