import type {
  FetchAndTransformComboBoxResultsParams,
  SearchComboBoxItem,
  SearchComboboxResults,
  TransformSearchQueryResultsParams,
} from './search-combobox.types'
import { createSearchComboboxQueryFetcher } from './__generated__/search-combobox.gql'
import { graphqlRequesterOptions } from '../../../config/graphql-requester-options'
import { getSearchPageUrlFromLocation } from '../utils/get-search-page-url-from-location'
import { maybePrefixWithSlash } from '@rentpath/web-utils'

export const fetchSearchComboboxQuery = createSearchComboboxQueryFetcher(
  graphqlRequesterOptions
)

export const ResultType = {
  SAVED_SEARCH: 'SAVED_SEARCH',
  PLACE: 'PLACE',
  PROPERTY: 'PROPERTY',
} as const

export const RESULTS_DEFAULT: SearchComboboxResults = {
  places: [],
  properties: [],
}

export function getIsPropertyPath(result: SearchComboBoxItem) {
  return result?.resultType === ResultType.PROPERTY
}

function shouldShowResult(result: SearchComboBoxItem, query: string) {
  return (
    result?.value?.startsWith('zip-') ||
    (query.match(/^\d{4,5}$/) && result?.type === 'ZIP') ||
    result?.resultType === ResultType.PROPERTY ||
    Boolean(result?.label.length)
  )
}

export function transformSearchQueryResults({
  value,
  query,
  refinements,
  propertyTypes,
  singlePropertyTypeFilterParam,
  moveInDate,
  pathName,
}: TransformSearchQueryResultsParams): SearchComboboxResults {
  const dataValue = value
  const locationSearch = dataValue.locationSearch || []
  const propertySearch = dataValue.propertySearch?.listings || []

  function handleShowResult(
    item: SearchComboBoxItem
  ): item is SearchComboBoxItem {
    return (
      null != item &&
      typeof item?.label === 'string' &&
      typeof item?.value === 'string' &&
      shouldShowResult(item, query)
    )
  }

  const places = locationSearch
    .map((locationItem) => {
      const url =
        getSearchPageUrlFromLocation({
          location: locationItem,
          refinements,
          propertyTypes,
          singlePropertyTypeFilterParam,
          moveInDate,
          pathName,
        }) || ''

      return {
        label: locationItem.name || '',
        value: url,
        type: locationItem.type,
        resultType: ResultType.PLACE,
      }
    })
    .filter(handleShowResult)

  const properties =
    propertySearch
      .map((item) => ({
        label: `${item?.name}, ${item?.location?.city}, ${item?.location?.stateAbbr}`,
        value: maybePrefixWithSlash(item?.urlPathname || ''),
        dataTagItem: 'property_name',
        resultType: ResultType.PROPERTY,
        propertyType: item.propertyType,
        address: item.addressFull,
        name: item.name,
        location: item.location,
      }))
      ?.filter(handleShowResult) ?? []

  const numberOfResults = {
    places: properties.length >= 4 ? 6 : 10 - properties.length,
    properties: places.length >= 6 ? 4 : 10 - places.length,
  }

  return {
    places: places?.slice(0, numberOfResults.places),
    properties: properties?.slice(0, numberOfResults.properties),
  }
}

function getIsAddressNumberSearch(query: string) {
  return query.match(/^[0-9]{5}? /)
} // If the query matches 5 numbers followed by a space, treat it as a property house number input

export function fetchAndTransformSearchComboBoxResults({
  query,
  refinements,
  propertyTypes,
  singlePropertyTypeFilterParam,
  moveInDate,
  pathName,
}: FetchAndTransformComboBoxResultsParams) {
  const zip = query.match(/^[0-9]{5}(?:-[0-9]{4})?/) // If query has a zip code, we want to strip everything else
  const isAddressNumberSearch = getIsAddressNumberSearch(query)
  const queryToSend = isAddressNumberSearch || !zip ? query : zip?.[0] || ''

  return fetchSearchComboboxQuery({ query: queryToSend }).mapOk((value) =>
    transformSearchQueryResults({
      value,
      query: queryToSend,
      refinements,
      propertyTypes,
      singlePropertyTypeFilterParam,
      moveInDate,
      pathName,
    })
  )
}
