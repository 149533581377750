import { atom } from 'jotai'
import type { LeadFormListing } from '../lead-form/lead-form-modal-body'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import type { RequestTourWizardListing } from '../request-a-tour/request-a-tour-wizard-modal'
import type { TourWizardListing } from '../tour-wizard/tour-wizard-modal'
import type { ThankYou_ListingFragment } from '../thank-you/__generated__/thank-you.gql'

type ThankYouModalState = {
  listing: ThankYou_ListingFragment | null
}

type LeadFormModalState = {
  listing: LeadFormListing | null
  currentRefinementSlugs?: string[]
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
  'data-tid'?: string
}

type RequestTourModalState = {
  listing: RequestTourWizardListing | null
  currentRefinementSlugs?: string[]
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
  'data-tid'?: string
}

type TourWizardModalState = {
  listing: TourWizardListing | null
  currentRefinementSlugs?: string[]
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
  'data-tid'?: string
}

export const leadFormModalStateAtom = atom<LeadFormModalState>({
  listing: null,
})

export const requestTourModalStateAtom = atom<RequestTourModalState>({
  listing: null,
})

export const tourWizardModalStateAtom = atom<TourWizardModalState>({
  listing: null,
})

export const thankYouModalStateAtom = atom<ThankYouModalState>({
  listing: null,
})
